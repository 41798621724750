<template>

    <router-view></router-view>

    <LoadingState v-if="loading" />
</template>


<script setup>

import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useModulesStore } from '/src/stores/modules';
import { useApplicationStore } from '/src/stores/application';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';


import LoadingState from './components/LoadingState.vue';

const route = useRoute()
const modules = useModulesStore()
const application = useApplicationStore()

const {loading} = storeToRefs(application)


watch(
    () => route.query,
    (query) => {
        application.setUtmParameters(query)
    },
    { deep: true, immediate: true, }
)

watch(
    () => route.params.part,
    (part) => {
        modules.setCurrentPart(part);
        modules.setCurrentChapter(0);
    },
    { deep: true, immediate: true, }
)

watch(
    () => route.params.chapter,
    (chapter) => {
        if (chapter !== undefined) {
            modules.setCurrentChapter(chapter);
        };
    },
    { deep: true, immediate: true, }
)

watch(
    () => route.params.module,
    (module) => {
        modules.setCurrentModule(module);
    },
    { deep: true, immediate: true, }
)


</script>